<template>
    <div v-if="loading" class="gst-passcode-modal__loading d-flex flex-column">
        <DataLoading class="my-5 py-5" />
    </div>
    <div v-else class="gst-passcode-modal d-flex flex-column">
        <div class="gst-passcode-modal__header d-flex flex-wrap py-4 mb-4 justify-center">
            <PrismicRichText :field="title" class="gst-passcode-modal__title" />
            <v-btn
                fab
                text
                x-small
                class="header-variant-1__btn-close text-capitalize px-0"
                color="white"
                @click="$emit('close')">
                <IconClose />
            </v-btn>
        </div>
        <PrismicRichText :field="content" class="gst-passcode-modal__content" />
        <form @submit="submit">
            <div class="gst-passcode-modal__form-wrapper d-flex px-8 flex-column flex-md-row">
                <BaseFormInput
                    id="passcodeModel.passcode"
                    v-model="passcodeModel.passcode"
                    :label="inputLabel"
                    maxlength="255"
                    :value="passcodeModel.passcode"
                    class="gst-passcode-modal__form-passcode"
                    :error-messages="extractValidationsMessagesFn( 'passcodeModel.passcode' )"
                    :show-check-icon="checkIcon"
                    :data-test-id="$testId('input.passcode')"
                    @blur="validation.passcodeModel.passcode.$touch()" />
                <BaseButton
                    :placeholder="buttonLabel"
                    class="gst-passcode-modal__button mt-1"
                    color="primary"
                    type="submit"
                    :disabled="validation.$invalid" />
            </div>
        </form>
        <PrismicRichText :field="modalMessage" class="gst-passcode-modal__modal--message px-8 mt-4" />
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import IconClose from '@core/shared/assets/icons/close.svg';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import BaseFormInput from '@tenant/app/components/inputs/BaseFormInput.vue';
    import PassCodeMixin from '@tenant/app/mixins/PassCodeMixin';


    export default ( {
        name: 'PasscodeModal',
        components: {
            BaseButton,
            BaseFormInput,
            IconClose,
            DataLoading
        },
        mixins: [
            PassCodeMixin
        ],
        props: {
            onUnlockFn: {
                type: Function,
                required: true
            }
        },
        data(){
            return {
                title: [],
                content: [],
                buttonLabel: '',
                inputLabel: '',
                modalMessage: [],
                validation: {},
                checkIcon: false,
                passcodeModel: {
                    passcode: '',
                },
                loading: true

            };
        },
        methods: {
            submit( e ) {
                e.preventDefault( );
                if ( !this.validation.$invalid ) {
                    !this.onUnlockFn || this.onUnlockFn( this.passcodeModel.passcode  );
                    this.$emit( 'close' );
                }
            },

            extractValidationsMessagesFn() {
                return this._extractValidationsMessages();
            },

            async loadData() {
                this.validation = this.$v;
                this.checkIcon = this.$v.passcodeModel.passcode.$dirty;

                const modalContent = await this.$prismic.client.getSingle( 'presale-passcode-modal' );


                if( modalContent ) {
                    this.title = modalContent.data.title;
                    this.content = modalContent.data.content;
                    this.buttonLabel = modalContent.data.button_label;
                    this.inputLabel = modalContent.data.input_label;
                    this.modalMessage = modalContent.data.modal_message;
                    this.loading = false;
                } else {
                    this.$emit( 'close' );
                    // eslint-disable-next-line no-console
                    console.error( 'Error fetching prismic modal data' );
                }
            }
        },
        mounted() {
            this.loadData();
        }
    } );
</script>

<style lang="scss">
@import "@scssMixins";
@import "@scssVariables";

.gst-passcode-modal__loading {
    height: auto;
    width: 492px;
    background-color: theme-color( 'white' );
}

.gst-passcode-modal {
    height: auto;
    background-color: theme-color( 'white' );
    max-width: 492px;

    .gst-passcode-modal__header {
        position: relative;
        background: theme-color( 'primary' );
        color: theme-color( 'white' );
        align-content: center;

        .header-variant-1__btn-close {
            position: absolute;
            top: 12px;
            right: 16px;

            .gst-svg-icon {
                fill: theme-color( 'white' );
            }
        }

        .gst-passcode-modal__title {
            h5 {
                line-height: line-height( 'xxxxl' );
                font-size: font-size( 'xxl' );
                font-weight: font-weight( 'large' );
            }
        }
    }

    .gst-passcode-modal__form-wrapper {
        width: 100%;
        align-items: center;
        gap: theme-spacing( 4 );

        .gst-passcode-modal__form-passcode {
            width: 100%;

            input {
                min-height: 44px;
            }
        }

        .gst-passcode-modal__button {
            height: 44px;
            min-width: 98px;

            span {
                font-weight: font-weight( 'large' );
                text-transform: capitalize;
            }
        }
    }

    .gst-base-form-input__errors-messages {
        margin-top: theme-spacing( 3 );
    }

    .gst-passcode-modal__modal--message {
        color: theme-color( 'tertiary' );
        font-size: font-size( 'xxs' );
    }

    .gst-passcode-modal__content {
        padding: 0 theme-spacing( 8 );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'regular' );
        word-break: break-word;
    }
}

@include mobile-only {
    .gst-passcode-modal__loading {
        width: 292px;
    }

    .gst-passcode-modal {
        .gst-passcode-modal__header {
            .gst-passcode-modal__title {
                h5 {
                    line-height: 26px;
                    font-size: 21px;
                }
            }
        }

        .gst-passcode-modal__content {
            p {
                font-size: font-size( 'l' );
                font-weight: font-weight( 'regular' );
            }
        }
    }

    .gst-passcode-modal__form-wrapper {
        gap: 0 !important;

        .gst-passcode-modal__button {
            height: 36px !important;
            width: 100%;
        }
    }
}
</style>
