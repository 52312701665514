import {
    required,
} from 'vuelidate/lib/validators';
import VuelidateMessages from '@core/mixins/forms/VuelidateMessages';

export default {
    mixins: [ VuelidateMessages ],
    computed: {
        _validationsMessages( ) {
            return {
                passcodeModel: {
                    passcode: {
                        required: (  ) => this.$t( '_common:messages.passcodeRequired' ),
                    },
                }
            };
        }
    },
    validations() {
        const ret = {
            passcodeModel: {
                passcode: {
                    required,
                },
            }

        };
        return ret;
    }
};